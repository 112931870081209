import { Playground, Props } from 'docz';
import { themes } from 'config/themes';
import { PageTitle } from 'docs/helpers/PageTitle';
import { Position } from "../../../../../src/components/Position/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  themes,
  PageTitle,
  Position,
  React
};