import { Playground, Props } from 'docz';
import { themes } from 'config/themes';
import { ArrowUpDownIcon } from 'icons/ArrowUpDownIcon';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Flex } from 'components/Flex';
import { Paper } from 'components/Paper';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { InlinePopover, PortalPopover, Popover } from "../../../../../src/compositions/Popover/index";
import { horizontal, vertical } from "../../../../../src/compositions/Popover/docs/helpers/config";
import * as React from 'react';
export default {
  Playground,
  Props,
  themes,
  ArrowUpDownIcon,
  Button,
  Card,
  Flex,
  Paper,
  Rhythm,
  Typography,
  PageTitle,
  ThemeWrapper,
  InlinePopover,
  PortalPopover,
  Popover,
  horizontal,
  vertical,
  React
};