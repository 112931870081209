import { Playground, Props } from 'docz';
import { TimesIcon } from 'icons/TimesIcon';
import { CheckIcon } from 'icons/internal/CheckIcon';
import { IconButton } from 'components/Button';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Chip, StyledChip } from "../../../../../src/compositions/Chip/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  TimesIcon,
  CheckIcon,
  IconButton,
  Rhythm,
  PageTitle,
  ThemeWrapper,
  Chip,
  StyledChip,
  React
};