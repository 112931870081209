import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Footer, StyledFooter } from "../../../../../src/components/Footer/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  PageTitle,
  ThemeWrapper,
  Footer,
  StyledFooter,
  React
};