import { ArgsTable, Description, Primary, Stories, Subtitle, PRIMARY_STORY } from '@storybook/addon-docs';
import { Button } from 'components/Button';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { Canvas } from 'stories/helpers/Canvas';
import { PageTitle } from 'stories/helpers/PageTitle';
import { Tabs, TabsContainer, TabList, TabPanelGroup } from "../../../../../src/compositions/Tabs/index";
import { items } from "../../../../../src/compositions/Tabs/stories/helpers/items";
import * as React from 'react';
export default {
  ArgsTable,
  Description,
  Primary,
  Stories,
  Subtitle,
  PRIMARY_STORY,
  Button,
  Rhythm,
  Typography,
  Canvas,
  PageTitle,
  Tabs,
  TabsContainer,
  TabList,
  TabPanelGroup,
  items,
  React
};