import { action } from '@storybook/addon-actions';
import { ArgsTable, Description, Primary, Stories, Subtitle, PRIMARY_STORY } from '@storybook/addon-docs';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { Canvas } from 'stories/helpers/Canvas';
import { PageTitle } from 'stories/helpers/PageTitle';
import { FormComponentDemo } from "../../../../../src/components/Form/stories/helpers/FormComponentDemo";
import { CheckboxGroup } from "../../../../../src/components/Form/Checkbox/CheckboxGroup";
import { NotifiedCheckboxGroup } from "../../../../../src/components/Form/Checkbox/NotifiedCheckboxGroup";
import { checkboxes } from "../../../../../src/components/Form/Checkbox/stories/helpers/options";
import * as React from 'react';
export default {
  action,
  ArgsTable,
  Description,
  Primary,
  Stories,
  Subtitle,
  PRIMARY_STORY,
  Looper,
  Rhythm,
  Canvas,
  PageTitle,
  FormComponentDemo,
  CheckboxGroup,
  NotifiedCheckboxGroup,
  checkboxes,
  React
};