import { Playground, Props } from 'docz';
import { TimesIcon } from 'icons/TimesIcon';
import { CheckIcon } from 'icons/internal/CheckIcon';
import { HeartIcon } from 'icons/internal/HeartIcon';
import { Avatar } from 'components/Avatar';
import { IconButton } from 'components/Button';
import { Flex } from 'components/Flex';
import { IconText } from 'components/IconText';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { ColoredTag, StyledTag, Tag, TagGroup } from "../../../../../src/components/Tag/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  TimesIcon,
  CheckIcon,
  HeartIcon,
  Avatar,
  IconButton,
  Flex,
  IconText,
  Looper,
  Rhythm,
  Typography,
  ColorLooper,
  PageTitle,
  ThemeWrapper,
  ColoredTag,
  StyledTag,
  Tag,
  TagGroup,
  React
};