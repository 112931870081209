import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { PermanentSidePanel } from "../../../../../src/components/Panels/SidePanel/PermanentSidePanel";
import { SidePanel } from "../../../../../src/components/Panels/SidePanel/SidePanel";
import { PermanentStackPanel } from "../../../../../src/components/Panels/StackPanel/PermanentStackPanel";
import { StackPanel } from "../../../../../src/components/Panels/StackPanel/StackPanel";
import { MainPanel, PanelContainer } from "../../../../../src/components/Panels/index";
import { SidePanelDemo } from "../../../../../src/components/Panels/docs/helpers/SidePanelDemo";
import { StackPanelDemo } from "../../../../../src/components/Panels/docs/helpers/StackPanelDemo";
import * as React from 'react';
export default {
  Playground,
  Props,
  PageTitle,
  PermanentSidePanel,
  SidePanel,
  PermanentStackPanel,
  StackPanel,
  MainPanel,
  PanelContainer,
  SidePanelDemo,
  StackPanelDemo,
  React
};