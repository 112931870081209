import { Playground, Props } from 'docz';
import { Flex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from "../../../../../src/components/Form/docs/helpers/FormComponentDemo";
import { Toggle, NotifiedToggle } from "../../../../../src/components/Form/Toggle/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Flex,
  Looper,
  Rhythm,
  PageTitle,
  ThemeWrapper,
  FormComponentDemo,
  Toggle,
  NotifiedToggle,
  React
};