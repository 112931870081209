import { ArgsTable, Description, Primary, Stories, Subtitle, PRIMARY_STORY } from '@storybook/addon-docs';
import { StateWrapper } from 'docs/helpers/StateWrapper';
import { Canvas } from 'stories/helpers/Canvas';
import { PageTitle } from 'stories/helpers/PageTitle';
import { Pagination } from "../../../../../src/compositions/Pagination/Pagination";
import * as React from 'react';
export default {
  ArgsTable,
  Description,
  Primary,
  Stories,
  Subtitle,
  PRIMARY_STORY,
  StateWrapper,
  Canvas,
  PageTitle,
  Pagination,
  React
};