import { Canvas, Meta, Story } from '@storybook/addon-docs';
import { PhorkIcon } from 'icons/PhorkIcon';
import { ColorSwatches } from 'docs/helpers/ColorSwatches';
import { ColorTheme } from 'docs/helpers/ColorTheme';
import { PageTitle } from 'stories/helpers/PageTitle';
import { ThemeWrapper } from 'stories/helpers/ThemeWrapper';
import * as React from 'react';
export default {
  Canvas,
  Meta,
  Story,
  PhorkIcon,
  ColorSwatches,
  ColorTheme,
  PageTitle,
  ThemeWrapper,
  React
};