import { Playground, Props } from 'docz';
import { ucfirst } from 'utils';
import { SpinnerIcon } from 'icons/SpinnerIcon';
import { Flex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Button, ColoredButton, StyledButton } from "../../../../../src/components/Button/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  ucfirst,
  SpinnerIcon,
  Flex,
  Looper,
  Rhythm,
  ColorLooper,
  PageTitle,
  ThemeWrapper,
  Button,
  ColoredButton,
  StyledButton,
  React
};