import { ArgsTable, Description, Primary, Stories, Subtitle, PRIMARY_STORY } from '@storybook/addon-docs';
import { Flex } from 'components/Flex';
import { Canvas } from 'stories/helpers/Canvas';
import { PageTitle } from 'stories/helpers/PageTitle';
import { Loader } from "../../../../../src/components/Loader/Loader";
import * as React from 'react';
export default {
  ArgsTable,
  Description,
  Primary,
  Stories,
  Subtitle,
  PRIMARY_STORY,
  Flex,
  Canvas,
  PageTitle,
  Loader,
  React
};