import { Playground } from 'docz';
import { Flex } from 'components/Flex';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { AccessibilityProvider, useAccessibility, useInputEventType } from "../../../../../src/context/Accessibility/index";
import styles from "../../../../../src/context/Accessibility/docs/AccessibilityDemo.module.css";
import * as React from 'react';
export default {
  Playground,
  Flex,
  Rhythm,
  Typography,
  PageTitle,
  ThemeWrapper,
  AccessibilityProvider,
  useAccessibility,
  useInputEventType,
  styles,
  React
};