import { Playground, Props } from 'docz';
import { Flex, StyledFlex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { FlexBox } from "../../../../../src/components/Flex/docs/helpers/FlexBox";
import * as React from 'react';
export default {
  Playground,
  Props,
  Flex,
  StyledFlex,
  Looper,
  PageTitle,
  FlexBox,
  React
};