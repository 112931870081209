import { Playground, Props } from 'docz';
import { themes } from 'config/themes';
import { TimesIcon } from 'icons/TimesIcon';
import { Avatar } from 'components/Avatar';
import { Flex } from 'components/Flex';
import { Rhythm } from 'components/Rhythm';
import { Typography, TypographyWithSvg } from 'components/Typography';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { DropdownWithTags } from "../../../../../src/compositions/Dropdown/index";
import { options, handleFilter } from "../../../../../src/compositions/Dropdown/docs/helpers/options";
import * as React from 'react';
export default {
  Playground,
  Props,
  themes,
  TimesIcon,
  Avatar,
  Flex,
  Rhythm,
  Typography,
  TypographyWithSvg,
  PageTitle,
  ThemeWrapper,
  DropdownWithTags,
  options,
  handleFilter,
  React
};