import { Playground, Props } from 'docz';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Paper } from "../../../../../src/components/Paper/Paper";
import { ColoredPaper, StyledPaper } from "../../../../../src/components/Paper/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  ColorLooper,
  PageTitle,
  ThemeWrapper,
  Paper,
  ColoredPaper,
  StyledPaper,
  React
};