import { action } from '@storybook/addon-actions';
import { ArgsTable, Description, Primary, Stories, Subtitle, PRIMARY_STORY } from '@storybook/addon-docs';
import { ucfirst } from 'utils';
import { SpinnerIcon } from 'icons/SpinnerIcon';
import { Flex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { Canvas } from 'stories/helpers/Canvas';
import { PageTitle } from 'stories/helpers/PageTitle';
import { Button } from "../../../../../src/components/Button/Button";
import * as React from 'react';
export default {
  action,
  ArgsTable,
  Description,
  Primary,
  Stories,
  Subtitle,
  PRIMARY_STORY,
  ucfirst,
  SpinnerIcon,
  Flex,
  Looper,
  Rhythm,
  Canvas,
  PageTitle,
  Button,
  React
};