import { Playground, Props } from 'docz';
import { Looper } from 'components/Looper';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Divider, ColoredDivider, StyledDivider } from "../../../../../src/components/Divider/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Looper,
  ColorLooper,
  PageTitle,
  ThemeWrapper,
  Divider,
  ColoredDivider,
  StyledDivider,
  React
};