import { Playground, Props } from 'docz';
import { TimesIcon } from 'icons/TimesIcon';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { IconText } from "../../../../../src/components/IconText/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  TimesIcon,
  Rhythm,
  PageTitle,
  IconText,
  React
};