import { ArgsTable, Description, Primary, Stories, Subtitle, PRIMARY_STORY } from '@storybook/addon-docs';
import { TimesIcon } from 'icons/TimesIcon';
import { TagIcon } from 'icons/internal/TagIcon';
import { Rhythm } from 'components/Rhythm/Rhythm';
import { ChipContent } from 'compositions/Chip/ChipContent';
import { Canvas } from 'stories/helpers/Canvas';
import { PageTitle } from 'stories/helpers/PageTitle';
import { DropdownWithTags } from "../../../../../src/compositions/Dropdown/DropdownWithTags";
import { options } from "../../../../../src/compositions/Dropdown/stories/helpers/options";
import * as React from 'react';
export default {
  ArgsTable,
  Description,
  Primary,
  Stories,
  Subtitle,
  PRIMARY_STORY,
  TimesIcon,
  TagIcon,
  Rhythm,
  ChipContent,
  Canvas,
  PageTitle,
  DropdownWithTags,
  options,
  React
};