import { nanoid } from 'nanoid';
import * as React from 'react';
import { SvgIconProps } from '../../types/svgIcon';
import { useIconSize } from '../../hooks/useIconSize';

export function RefreshIcon({ title, titleId = nanoid(), ...initProps }: SvgIconProps): React.ReactElement<SVGElement> {
  const props = useIconSize(initProps);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" aria-labelledby={titleId} {...props}>
      {title === undefined ? (
        <title id={titleId}>{'Refresh'}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <path
        fill="currentColor"
        d="M93.31 49.76L81.68 67.21 70.04 49.76h7.28a27.602 27.602 0 00-6.28-17.5c-.02-.02-.03-.04-.05-.06a27.694 27.694 0 00-10.42-7.81c-.07-.03-.13-.06-.2-.09-.68-.29-1.37-.53-2.08-.76-.19-.07-.38-.14-.57-.2-.62-.18-1.25-.34-1.88-.48-.28-.06-.56-.14-.84-.19-.64-.13-1.29-.22-1.95-.3-.24-.03-.49-.07-.73-.09-.8-.08-1.61-.12-2.43-.12a27.35 27.35 0 00-16.04 4.98 4.37 4.37 0 01-6.08-1.07 4.37 4.37 0 011.07-6.08 36.078 36.078 0 0120.76-6.56c.03 0 .06-.01.09-.01.17 0 .34.02.52.02.96.02 1.92.06 2.88.15.4.04.79.1 1.19.15.58.08 1.15.12 1.73.22.21.04.41.1.62.14.43.09.85.19 1.28.29.77.18 1.53.36 2.29.59.31.1.62.21.93.31.86.29 1.72.59 2.56.94.14.06.29.13.43.19 5.25 2.27 9.89 5.72 13.57 10.2.07.08.13.16.19.24.55.67 1.09 1.34 1.59 2.05.07.1.11.2.17.3 4.03 5.85 6.4 12.93 6.4 20.55h7.27zm-21.7 23.7c1.39 1.98.9 4.7-1.07 6.08-6.18 4.33-13.39 6.59-20.78 6.59-1.21 0-2.42-.08-3.63-.2-.25-.02-.48-.07-.73-.09-.67-.08-1.35-.15-2.02-.27-.2-.04-.39-.09-.59-.13-.48-.1-.96-.22-1.43-.33-.72-.17-1.43-.34-2.12-.55-.38-.11-.75-.24-1.13-.37-.79-.26-1.58-.54-2.35-.86l-.66-.3a30.8 30.8 0 01-2.65-1.28c-.08-.04-.16-.09-.24-.14a35.483 35.483 0 01-8.17-6.12c-.07-.07-.14-.14-.2-.21-.73-.74-1.43-1.5-2.09-2.3-.18-.21-.33-.43-.5-.65-.45-.56-.92-1.12-1.34-1.72-.05-.08-.09-.16-.14-.25-4.05-5.86-6.44-12.95-6.44-20.6H6.06L17.7 32.31l11.63 17.45h-7.27c0 6.51 2.28 12.49 6.06 17.21.11.14.21.29.32.42.53.63 1.08 1.24 1.65 1.82l.09.09a27.44 27.44 0 006.29 4.71c.03.01.06.03.09.05.68.37 1.38.7 2.1 1.02.14.06.27.12.41.18.61.25 1.24.48 1.87.69.26.08.52.18.79.26.54.16 1.1.29 1.66.42.35.09.69.17 1.04.24.58.11 1.16.2 1.75.27.31.04.61.09.92.12.74.07 1.5.1 2.26.11a27.4 27.4 0 0016.18-4.98c1.96-1.38 4.69-.9 6.07 1.07z"
      />
    </svg>
  );
}

RefreshIcon.displayName = 'RefreshIcon';
