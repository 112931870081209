import { Playground, Props } from 'docz';
import { themes } from 'config/themes';
import { Flex } from 'components/Flex';
import { Rhythm } from 'components/Rhythm';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Badge, ColoredBadge, StyledBadge } from "../../../../../src/components/Badge/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  themes,
  Flex,
  Rhythm,
  ColorLooper,
  PageTitle,
  ThemeWrapper,
  Badge,
  ColoredBadge,
  StyledBadge,
  React
};