import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Label, StyledLabel } from "../../../../../src/components/Form/Label/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  PageTitle,
  ThemeWrapper,
  Label,
  StyledLabel,
  React
};