import { Playground, Props } from 'docz';
import { SearchIcon } from 'icons/SearchIcon';
import { TimesIcon } from 'icons/TimesIcon';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from "../../../../../src/components/Form/docs/helpers/FormComponentDemo";
import { Select, NotifiedSelect } from "../../../../../src/components/Form/Select/index";
import { options } from "../../../../../src/components/Form/Select/docs/helpers/options";
import { variants, sizes } from "../../../../../src/components/Form/Select/docs/helpers/variants";
import * as React from 'react';
export default {
  Playground,
  Props,
  SearchIcon,
  TimesIcon,
  Looper,
  Rhythm,
  PageTitle,
  ThemeWrapper,
  FormComponentDemo,
  Select,
  NotifiedSelect,
  options,
  variants,
  sizes,
  React
};