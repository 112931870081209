import { Canvas, Meta, Story } from '@storybook/addon-docs';
import { RightPanelIcon } from 'icons/internal/RightPanelIcon';
import { IconButton } from 'components/Button/IconButton';
import { PageTitle } from 'stories/helpers/PageTitle';
import { MainPanel, PanelContainer, PermanentSidePanel, PermanentStackPanel, SidePanel, StackPanel } from "../../../../../src/components/Panels";
import { PanelsWrapper } from "../../../../../src/components/Panels/stories/helpers/PanelsWrapper";
import * as React from 'react';
export default {
  Canvas,
  Meta,
  Story,
  RightPanelIcon,
  IconButton,
  PageTitle,
  MainPanel,
  PanelContainer,
  PermanentSidePanel,
  PermanentStackPanel,
  SidePanel,
  StackPanel,
  PanelsWrapper,
  React
};