import { Playground, Props } from 'docz';
import { Label, Textbox } from 'components/Form';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { LabelWrapper } from "../../../../../src/components/Form/LabelWrapper/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Label,
  Textbox,
  PageTitle,
  ThemeWrapper,
  LabelWrapper,
  React
};