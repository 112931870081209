import { Playground } from 'docz';
import { PhorkIcon } from 'icons/PhorkIcon';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ColorSwatches } from "../../../../../src/docs/helpers/ColorSwatches";
import { ColorTheme } from "../../../../../src/docs/helpers/ColorTheme";
import { ThemeWrapper } from "../../../../../src/docs/helpers/ThemeWrapper";
import * as React from 'react';
export default {
  Playground,
  PhorkIcon,
  PageTitle,
  ColorSwatches,
  ColorTheme,
  ThemeWrapper,
  React
};