// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-avatar-docs-avatar-mdx": () => import("./../../../../src/components/Avatar/docs/avatar.mdx" /* webpackChunkName: "component---src-components-avatar-docs-avatar-mdx" */),
  "component---src-components-badge-docs-badge-mdx": () => import("./../../../../src/components/Badge/docs/badge.mdx" /* webpackChunkName: "component---src-components-badge-docs-badge-mdx" */),
  "component---src-components-button-docs-button-group-mdx": () => import("./../../../../src/components/Button/docs/buttonGroup.mdx" /* webpackChunkName: "component---src-components-button-docs-button-group-mdx" */),
  "component---src-components-button-docs-button-mdx": () => import("./../../../../src/components/Button/docs/button.mdx" /* webpackChunkName: "component---src-components-button-docs-button-mdx" */),
  "component---src-components-button-docs-icon-button-mdx": () => import("./../../../../src/components/Button/docs/iconButton.mdx" /* webpackChunkName: "component---src-components-button-docs-icon-button-mdx" */),
  "component---src-components-button-docs-icon-text-button-mdx": () => import("./../../../../src/components/Button/docs/iconTextButton.mdx" /* webpackChunkName: "component---src-components-button-docs-icon-text-button-mdx" */),
  "component---src-components-card-docs-card-mdx": () => import("./../../../../src/components/Card/docs/card.mdx" /* webpackChunkName: "component---src-components-card-docs-card-mdx" */),
  "component---src-components-divider-docs-divider-mdx": () => import("./../../../../src/components/Divider/docs/divider.mdx" /* webpackChunkName: "component---src-components-divider-docs-divider-mdx" */),
  "component---src-components-flex-docs-flex-mdx": () => import("./../../../../src/components/Flex/docs/flex.mdx" /* webpackChunkName: "component---src-components-flex-docs-flex-mdx" */),
  "component---src-components-footer-docs-footer-mdx": () => import("./../../../../src/components/Footer/docs/footer.mdx" /* webpackChunkName: "component---src-components-footer-docs-footer-mdx" */),
  "component---src-components-form-checkbox-docs-checkbox-mdx": () => import("./../../../../src/components/Form/Checkbox/docs/checkbox.mdx" /* webpackChunkName: "component---src-components-form-checkbox-docs-checkbox-mdx" */),
  "component---src-components-form-docs-form-mdx": () => import("./../../../../src/components/Form/docs/form.mdx" /* webpackChunkName: "component---src-components-form-docs-form-mdx" */),
  "component---src-components-form-label-docs-label-mdx": () => import("./../../../../src/components/Form/Label/docs/label.mdx" /* webpackChunkName: "component---src-components-form-label-docs-label-mdx" */),
  "component---src-components-form-label-wrapper-docs-label-wrapper-mdx": () => import("./../../../../src/components/Form/LabelWrapper/docs/labelWrapper.mdx" /* webpackChunkName: "component---src-components-form-label-wrapper-docs-label-wrapper-mdx" */),
  "component---src-components-form-notification-docs-notification-mdx": () => import("./../../../../src/components/Form/Notification/docs/notification.mdx" /* webpackChunkName: "component---src-components-form-notification-docs-notification-mdx" */),
  "component---src-components-form-radio-docs-radio-mdx": () => import("./../../../../src/components/Form/Radio/docs/radio.mdx" /* webpackChunkName: "component---src-components-form-radio-docs-radio-mdx" */),
  "component---src-components-form-select-docs-select-mdx": () => import("./../../../../src/components/Form/Select/docs/select.mdx" /* webpackChunkName: "component---src-components-form-select-docs-select-mdx" */),
  "component---src-components-form-slider-docs-slider-mdx": () => import("./../../../../src/components/Form/Slider/docs/slider.mdx" /* webpackChunkName: "component---src-components-form-slider-docs-slider-mdx" */),
  "component---src-components-form-stepper-docs-stepper-mdx": () => import("./../../../../src/components/Form/Stepper/docs/stepper.mdx" /* webpackChunkName: "component---src-components-form-stepper-docs-stepper-mdx" */),
  "component---src-components-form-textarea-docs-textarea-mdx": () => import("./../../../../src/components/Form/Textarea/docs/textarea.mdx" /* webpackChunkName: "component---src-components-form-textarea-docs-textarea-mdx" */),
  "component---src-components-form-textbox-docs-password-mdx": () => import("./../../../../src/components/Form/Textbox/docs/password.mdx" /* webpackChunkName: "component---src-components-form-textbox-docs-password-mdx" */),
  "component---src-components-form-textbox-docs-textbox-mdx": () => import("./../../../../src/components/Form/Textbox/docs/textbox.mdx" /* webpackChunkName: "component---src-components-form-textbox-docs-textbox-mdx" */),
  "component---src-components-form-toggle-docs-toggle-mdx": () => import("./../../../../src/components/Form/Toggle/docs/toggle.mdx" /* webpackChunkName: "component---src-components-form-toggle-docs-toggle-mdx" */),
  "component---src-components-header-docs-header-mdx": () => import("./../../../../src/components/Header/docs/header.mdx" /* webpackChunkName: "component---src-components-header-docs-header-mdx" */),
  "component---src-components-icon-text-docs-icon-text-mdx": () => import("./../../../../src/components/IconText/docs/iconText.mdx" /* webpackChunkName: "component---src-components-icon-text-docs-icon-text-mdx" */),
  "component---src-components-line-loader-docs-line-loader-mdx": () => import("./../../../../src/components/LineLoader/docs/lineLoader.mdx" /* webpackChunkName: "component---src-components-line-loader-docs-line-loader-mdx" */),
  "component---src-components-link-docs-link-mdx": () => import("./../../../../src/components/Link/docs/link.mdx" /* webpackChunkName: "component---src-components-link-docs-link-mdx" */),
  "component---src-components-list-docs-list-mdx": () => import("./../../../../src/components/List/docs/list.mdx" /* webpackChunkName: "component---src-components-list-docs-list-mdx" */),
  "component---src-components-list-registry-docs-list-registry-mdx": () => import("./../../../../src/components/ListRegistry/docs/listRegistry.mdx" /* webpackChunkName: "component---src-components-list-registry-docs-list-registry-mdx" */),
  "component---src-components-loader-docs-loader-mdx": () => import("./../../../../src/components/Loader/docs/loader.mdx" /* webpackChunkName: "component---src-components-loader-docs-loader-mdx" */),
  "component---src-components-panels-docs-panels-mdx": () => import("./../../../../src/components/Panels/docs/panels.mdx" /* webpackChunkName: "component---src-components-panels-docs-panels-mdx" */),
  "component---src-components-paper-docs-paper-mdx": () => import("./../../../../src/components/Paper/docs/paper.mdx" /* webpackChunkName: "component---src-components-paper-docs-paper-mdx" */),
  "component---src-components-position-docs-position-mdx": () => import("./../../../../src/components/Position/docs/position.mdx" /* webpackChunkName: "component---src-components-position-docs-position-mdx" */),
  "component---src-components-progress-docs-progress-mdx": () => import("./../../../../src/components/Progress/docs/progress.mdx" /* webpackChunkName: "component---src-components-progress-docs-progress-mdx" */),
  "component---src-components-rhythm-docs-rhythm-mdx": () => import("./../../../../src/components/Rhythm/docs/rhythm.mdx" /* webpackChunkName: "component---src-components-rhythm-docs-rhythm-mdx" */),
  "component---src-components-scroll-sync-docs-scroll-sync-mdx": () => import("./../../../../src/components/ScrollSync/docs/scrollSync.mdx" /* webpackChunkName: "component---src-components-scroll-sync-docs-scroll-sync-mdx" */),
  "component---src-components-shade-docs-shade-mdx": () => import("./../../../../src/components/Shade/docs/shade.mdx" /* webpackChunkName: "component---src-components-shade-docs-shade-mdx" */),
  "component---src-components-tag-docs-tag-group-mdx": () => import("./../../../../src/components/Tag/docs/tagGroup.mdx" /* webpackChunkName: "component---src-components-tag-docs-tag-group-mdx" */),
  "component---src-components-tag-docs-tag-mdx": () => import("./../../../../src/components/Tag/docs/tag.mdx" /* webpackChunkName: "component---src-components-tag-docs-tag-mdx" */),
  "component---src-components-triangle-docs-triangle-mdx": () => import("./../../../../src/components/Triangle/docs/triangle.mdx" /* webpackChunkName: "component---src-components-triangle-docs-triangle-mdx" */),
  "component---src-components-typography-docs-typography-mdx": () => import("./../../../../src/components/Typography/docs/typography.mdx" /* webpackChunkName: "component---src-components-typography-docs-typography-mdx" */),
  "component---src-compositions-accordion-docs-accordion-mdx": () => import("./../../../../src/compositions/Accordion/docs/accordion.mdx" /* webpackChunkName: "component---src-compositions-accordion-docs-accordion-mdx" */),
  "component---src-compositions-banner-docs-banner-mdx": () => import("./../../../../src/compositions/Banner/docs/banner.mdx" /* webpackChunkName: "component---src-compositions-banner-docs-banner-mdx" */),
  "component---src-compositions-chip-docs-chip-mdx": () => import("./../../../../src/compositions/Chip/docs/chip.mdx" /* webpackChunkName: "component---src-compositions-chip-docs-chip-mdx" */),
  "component---src-compositions-dropdown-docs-dropdown-mdx": () => import("./../../../../src/compositions/Dropdown/docs/dropdown.mdx" /* webpackChunkName: "component---src-compositions-dropdown-docs-dropdown-mdx" */),
  "component---src-compositions-dropdown-docs-dropdown-with-tags-mdx": () => import("./../../../../src/compositions/Dropdown/docs/dropdownWithTags.mdx" /* webpackChunkName: "component---src-compositions-dropdown-docs-dropdown-with-tags-mdx" */),
  "component---src-compositions-dropover-docs-dropover-mdx": () => import("./../../../../src/compositions/Dropover/docs/dropover.mdx" /* webpackChunkName: "component---src-compositions-dropover-docs-dropover-mdx" */),
  "component---src-compositions-icon-count-docs-icon-count-mdx": () => import("./../../../../src/compositions/IconCount/docs/iconCount.mdx" /* webpackChunkName: "component---src-compositions-icon-count-docs-icon-count-mdx" */),
  "component---src-compositions-icon-group-docs-icon-group-mdx": () => import("./../../../../src/compositions/IconGroup/docs/iconGroup.mdx" /* webpackChunkName: "component---src-compositions-icon-group-docs-icon-group-mdx" */),
  "component---src-compositions-interactive-list-docs-interactive-list-mdx": () => import("./../../../../src/compositions/InteractiveList/docs/interactiveList.mdx" /* webpackChunkName: "component---src-compositions-interactive-list-docs-interactive-list-mdx" */),
  "component---src-compositions-modal-docs-modal-mdx": () => import("./../../../../src/compositions/Modal/docs/modal.mdx" /* webpackChunkName: "component---src-compositions-modal-docs-modal-mdx" */),
  "component---src-compositions-navigation-docs-navigation-mdx": () => import("./../../../../src/compositions/Navigation/docs/navigation.mdx" /* webpackChunkName: "component---src-compositions-navigation-docs-navigation-mdx" */),
  "component---src-compositions-pagination-docs-pagination-mdx": () => import("./../../../../src/compositions/Pagination/docs/pagination.mdx" /* webpackChunkName: "component---src-compositions-pagination-docs-pagination-mdx" */),
  "component---src-compositions-popover-docs-popover-mdx": () => import("./../../../../src/compositions/Popover/docs/popover.mdx" /* webpackChunkName: "component---src-compositions-popover-docs-popover-mdx" */),
  "component---src-compositions-status-bubble-docs-avatar-status-bubble-mdx": () => import("./../../../../src/compositions/StatusBubble/docs/avatarStatusBubble.mdx" /* webpackChunkName: "component---src-compositions-status-bubble-docs-avatar-status-bubble-mdx" */),
  "component---src-compositions-status-bubble-docs-icon-status-bubble-mdx": () => import("./../../../../src/compositions/StatusBubble/docs/iconStatusBubble.mdx" /* webpackChunkName: "component---src-compositions-status-bubble-docs-icon-status-bubble-mdx" */),
  "component---src-compositions-tabs-docs-tabs-mdx": () => import("./../../../../src/compositions/Tabs/docs/tabs.mdx" /* webpackChunkName: "component---src-compositions-tabs-docs-tabs-mdx" */),
  "component---src-compositions-textbox-group-docs-textbox-group-mdx": () => import("./../../../../src/compositions/TextboxGroup/docs/textboxGroup.mdx" /* webpackChunkName: "component---src-compositions-textbox-group-docs-textbox-group-mdx" */),
  "component---src-compositions-timeline-docs-timeline-mdx": () => import("./../../../../src/compositions/Timeline/docs/timeline.mdx" /* webpackChunkName: "component---src-compositions-timeline-docs-timeline-mdx" */),
  "component---src-compositions-toast-docs-toast-mdx": () => import("./../../../../src/compositions/Toast/docs/toast.mdx" /* webpackChunkName: "component---src-compositions-toast-docs-toast-mdx" */),
  "component---src-compositions-tooltip-docs-tooltip-mdx": () => import("./../../../../src/compositions/Tooltip/docs/tooltip.mdx" /* webpackChunkName: "component---src-compositions-tooltip-docs-tooltip-mdx" */),
  "component---src-context-accessibility-docs-accessbility-mdx": () => import("./../../../../src/context/Accessibility/docs/accessbility.mdx" /* webpackChunkName: "component---src-context-accessibility-docs-accessbility-mdx" */),
  "component---src-context-size-docs-size-mdx": () => import("./../../../../src/context/Size/docs/size.mdx" /* webpackChunkName: "component---src-context-size-docs-size-mdx" */),
  "component---src-docs-colors-mdx": () => import("./../../../../src/docs/colors.mdx" /* webpackChunkName: "component---src-docs-colors-mdx" */),
  "component---src-docs-icons-mdx": () => import("./../../../../src/docs/icons.mdx" /* webpackChunkName: "component---src-docs-icons-mdx" */),
  "component---src-docs-index-mdx": () => import("./../../../../src/docs/index.mdx" /* webpackChunkName: "component---src-docs-index-mdx" */),
  "component---src-docs-playground-mdx": () => import("./../../../../src/docs/playground.mdx" /* webpackChunkName: "component---src-docs-playground-mdx" */),
  "component---src-docs-render-mdx": () => import("./../../../../src/docs/render.mdx" /* webpackChunkName: "component---src-docs-render-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

