import { Playground, Props } from 'docz';
import { TimesIcon } from 'icons/TimesIcon';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { StateWrapper } from 'docs/helpers/StateWrapper';
import { TimeoutWrapper } from 'docs/helpers/TimeoutWrapper';
import { IconGroup } from "../../../../../src/compositions/IconGroup/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  TimesIcon,
  Rhythm,
  PageTitle,
  StateWrapper,
  TimeoutWrapper,
  IconGroup,
  React
};