import { Canvas, Meta } from '@storybook/addon-docs';
import * as icons from 'icons';
import * as internal from 'icons/internal';
import { Flex } from 'components/Flex';
import { Paper } from 'components/Paper';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { IconLooper } from 'docs/helpers/IconLooper';
import { PageTitle } from 'stories/helpers/PageTitle';
import * as React from 'react';
export default {
  Canvas,
  Meta,
  icons,
  internal,
  Flex,
  Paper,
  Rhythm,
  Typography,
  IconLooper,
  PageTitle,
  React
};