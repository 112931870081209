import { Playground } from 'docz';
import * as icons from 'icons';
import * as internal from 'icons/internal';
import { Flex } from 'components/Flex';
import { Paper } from 'components/Paper';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { IconLooper } from "../../../../../src/docs/helpers/IconLooper";
import { ThemeWrapper } from "../../../../../src/docs/helpers/ThemeWrapper";
import * as React from 'react';
export default {
  Playground,
  icons,
  internal,
  Flex,
  Paper,
  Rhythm,
  Typography,
  IconLooper,
  ThemeWrapper,
  React
};