import { Canvas, Meta } from '@storybook/addon-docs';
import { Flex } from 'components/Flex';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { PageTitle } from 'stories/helpers/PageTitle';
import { ThemeWrapper } from 'stories/helpers/ThemeWrapper';
import { AccessibilityProvider, useAccessibility, useInputEventType } from "../../../../../src/context/Accessibility/index";
import styles from "../../../../../src/context/Accessibility/stories/AccessibilityDemo.module.css";
import * as React from 'react';
export default {
  Canvas,
  Meta,
  Flex,
  Rhythm,
  Typography,
  PageTitle,
  ThemeWrapper,
  AccessibilityProvider,
  useAccessibility,
  useInputEventType,
  styles,
  React
};