import { Playground, Props } from 'docz';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from "../../../../../src/components/Form/docs/helpers/FormComponentDemo";
import { Textarea, NotifiedTextarea } from "../../../../../src/components/Form/Textarea/index";
import { variants, sizes } from "../../../../../src/components/Form/Textarea/docs/helpers/variants";
import * as React from 'react';
export default {
  Playground,
  Props,
  Looper,
  Rhythm,
  PageTitle,
  ThemeWrapper,
  FormComponentDemo,
  Textarea,
  NotifiedTextarea,
  variants,
  sizes,
  React
};