import { Playground, Props } from 'docz';
import { Link } from 'components/Link';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Typography } from "../../../../../src/components/Typography/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Link,
  Rhythm,
  PageTitle,
  ThemeWrapper,
  Typography,
  React
};