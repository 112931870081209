import { Playground, Props } from 'docz';
import { Button } from 'components/Button';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { ColoredTabs, Tabs, Tab, TabList, TabPanelGroup, TabPanel, TabsContainer } from "../../../../../src/compositions/Tabs/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Button,
  Rhythm,
  Typography,
  ColorLooper,
  PageTitle,
  ThemeWrapper,
  ColoredTabs,
  Tabs,
  Tab,
  TabList,
  TabPanelGroup,
  TabPanel,
  TabsContainer,
  React
};