import { Playground, Props } from 'docz';
import { HeartIcon } from 'icons/internal/HeartIcon';
import { Button, ButtonGroup } from 'components/Button';
import { Flex } from 'components/Flex';
import { IconText } from 'components/IconText';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Modal, ModalBody, ModalConsumer, ModalContainer, ModalFooter, ModalFromContext, ModalHeader, Modals, ModalsFromContext } from "../../../../../src/compositions/Modal/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  HeartIcon,
  Button,
  ButtonGroup,
  Flex,
  IconText,
  Rhythm,
  Typography,
  PageTitle,
  ThemeWrapper,
  Modal,
  ModalBody,
  ModalConsumer,
  ModalContainer,
  ModalFooter,
  ModalFromContext,
  ModalHeader,
  Modals,
  ModalsFromContext,
  React
};