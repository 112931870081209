import { Playground, Props } from 'docz';
import { Looper } from 'components/Looper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { FormComponentDemo } from "../../../../../src/components/Form/docs/helpers/FormComponentDemo";
import { Slider, MultiColorSlider, StyledSlider, NotifiedSlider } from "../../../../../src/components/Form/Slider/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  Looper,
  PageTitle,
  FormComponentDemo,
  Slider,
  MultiColorSlider,
  StyledSlider,
  NotifiedSlider,
  React
};