import { Playground, Props } from 'docz';
import { themes } from 'config/themes';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Timeline, StraddledTimeline, TimelineDividerItem, TimelineMarkerItem } from "../../../../../src/compositions/Timeline/index";
import { items } from "../../../../../src/compositions/Timeline/docs/helpers/items";
import * as React from 'react';
export default {
  Playground,
  Props,
  themes,
  PageTitle,
  ThemeWrapper,
  Timeline,
  StraddledTimeline,
  TimelineDividerItem,
  TimelineMarkerItem,
  items,
  React
};