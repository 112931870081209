import { ArgsTable, Description, Primary, Stories, Subtitle, PRIMARY_STORY } from '@storybook/addon-docs';
import { PageTitle } from 'stories/helpers/PageTitle';
import * as React from 'react';
export default {
  ArgsTable,
  Description,
  Primary,
  Stories,
  Subtitle,
  PRIMARY_STORY,
  PageTitle,
  React
};